import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import ModalCnt from '../components/modal/ModalCnt';
import NumberPad from '../components/modal/NumberPad';
import ModalPw from '../components/modal/ModalPw';
import { useDispatch, useSelector } from 'react-redux';
import { dashBoardApi } from '../features/dashBoardSlice';
import { addCommasToNumber, formatBalance, getUtcTimeYmdHms } from '../utils/utils';
import { stakingPercentApi, stakingRequestApi } from '../features/staking/stakingSlice';
import Loading from '../components/loading/Loading';
import STATUSES from '../constants/reduxStatus';
import { useNavigate } from 'react-router-dom';

const LayoutBg = styled.div`
  min-height: 100vh;
  width: 100%;  
  background-color: #FAFFF1;
  padding: 100px 20px 0px;
  @media (max-width:780px){
    padding: 60px 20px 0px;
  }
`

const MainLayout = styled.div`
    max-width: 1290px;
    margin: 0 auto;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.49rem;
  padding: 110px 0px 13px 0px;
  text-align: center;
  @media (max-width:780px){
    padding: 40px 0px 14px 0px;
    font-size: 1.25rem;
    line-height: 1.465rem;
  }
`

const ContentWrap = styled.div`
    width: 100%;
`

const ContentTitle = styled.div`
    padding: 40px 0px 16px 0px;
    border-bottom: 2px solid #666666;
    display: flex;
    align-items: center;
    p{
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.318rem;
        padding-right: 6px;
    }
    span{
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.318rem;
        color: #A9A9A9;
    }
    @media (max-width:780px){
        padding: 30px 0px 10px 0px;
    }
`

const ContentInputWrap = styled.div`
  position: relative;
  max-width: 442px;
  width: 100%;
  img{
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
  @media (max-width:780px){
    max-width: 100%;
  }
`

const ContentInput = styled.input`
  max-width: 460px;
  width: 100%;
  height: 45px;
  border: 1px solid #C4FF69;
  border-radius: 5px;
  padding: 10px 20px;
  background-color: #FFFFFFD9;
  color: #1F1F1F;
  cursor: pointer;
  &:focus{
      outline: none;
      border-color: #16BFB6;
      background: #fff;
  }
  &::placeholder{
      font-size: 1rem;
      line-height: 1.5rem;
      color: #6F6F6F;
  }
  @media (max-width:780px){
    max-width: 100%;
    padding: 5px 10px;
    height: 35px;
    &::placeholder{
        font-size: 0.75rem;
        line-height: 1.5rem;
        color: #6F6F6F;
        letter-spacing: -0.24px;
    }
  }
`

const ContentBtn = styled.button`
   min-width: 107px;
  background-color: #16BFB6;
  border-radius: 5px;
  padding: 10px 20px;
  font-family: "NotoSansKR";
  font-weight: 700;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.5rem;
  cursor: pointer;
  margin-left: 15px;
  @media (max-width:780px){
    min-width: 65px;
    font-size: 0.75rem;
    padding: 5px 10px;
    margin-left: 8px;
  }
`

const ModifyBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  @media (max-width:780px){
    margin-top: 20px;
  }
`

const ModifyBtn = styled.button`
  font-family: 'Roboto';
  max-width: 460px;
  width: 100%;
  padding: 16px 0;
  background: linear-gradient(90deg, #16BFB6 0%, #2AE4A1 100%);
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  @media (max-width:780px){
    max-width: 100%;
    padding: 13px 0;
  }
`

const ContentRow = styled.div`
  border-bottom: 1px solid #D9D9D9;
  display: flex;
  align-items: center;
  @media (max-width:780px){
    flex-wrap: wrap;
    padding: 12px 5px;
    &.application_cnt{
      padding: 12px 5px 9px 5px;
    }
  }
`

const ContentRowTitle = styled.div`
  padding: 25px 15px;
  min-width: 210px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  color: #222222;
  @media (max-width:780px){
    padding: 0px 0px 8px 0px;
    width: 100%;
    &.application_cnt{
      padding: 0px 0px 6px 0px;
    }
  }
`

const ContentRowTxt = styled.div`
  width: 100%; 
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  color: #222222;
  word-break: break-all;
  @media (max-width:780px){
    font-size: 0.875rem;
    line-height: 1.026rem;
    letter-spacing: -0.42px;
  }
`

const ContentInputContainer = styled.div`
  width: 100%;
  display: flex;
`

const ContentInputTxt = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  line-height: 1.5rem;
  p{
    font-weight: 400;
    color: #6F6F6F;
  }
  span{
    min-width: 40px;
    font-weight: 700;
    text-align: right;
  }
  @media (max-width:780px){
    right: 10px; 
    gap: 6px; 
    font-size: 0.75rem;
    span{
      min-width: 30px;
    }
  }
`

const CheckWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  p {
    cursor: pointer;
    color: #222;
  }
  @media (max-width:780px) {
    p {
      font-size: 0.875rem;
    }
  }
`

const CheckIcon = styled.div`
  width: 20px;
  height: 20px;
  img {
    width: 100%;
    cursor: pointer;
  }
`

const GmmtIcon = styled.div`
  width: 30px;
  height: 30px;
  img {
    width: 100%;
  }
`

const GmmtCnt = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    font-size: 1.125rem;
  }
  @media (max-width:780px) {
    p {
      font-size: 1rem;
    }
  }
`

const ModalContentTitle = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.318rem;
  text-align: center;
`

const ModalContentCnt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 1.5rem;
  line-height: 1.758rem;
  font-weight: 700;
  padding: 28px 0px 41px 0px;
  p{
    color: #16BFB6;
  }
  @media (max-width:780px){
    padding: 19px 0px 40px 0px;
    font-size: 1.25rem;
    line-height: 1.465rem;
  }
`

const ModalWrap = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-top: 20px;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 10px;
  }

  &.apply {
    min-height: 300px;
    max-height: 500px;
    overflow-y: auto;
    padding: 20px;
  }

  @media (max-width:780px){ 
    margin-top: 12px;
    padding: 25px 15px 15px 15px;
  }
`

const ModalBtn = styled.div`
  margin-top: 20px;
  background: linear-gradient(90deg, #16BFB6 0%, #2AE4A1 100%);
  border-radius: 10px;
  font-weight: 700;
  color: #fff;
  font-size: 1rem;
  line-height: 1.5rem;
  width: 355px;
  height: 56px; 
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.delete{
      color: #AAAAAA;
      background: none;
      background-color: #D9D9D9;
  }
  @media (max-width:780px){
    margin-top: 15px;
    width: 250px;
    height: 50px;
  }
`

const ModalBtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  @media (max-width:780px){
    gap: 6px;
  }
`

function StakingRequest() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // gmmt 보유수량 조회
  const dashboardData = useSelector((state) => state.dashboard.data);
  const stakingPercentData = useSelector((state) => state.staking.data);
  const { status, error, data } = useSelector((state) => state.staking);
  // 거래 비밀번호 눈 아이콘
  const [eye, setEye] = useState([0]);
  // 라디오 버튼 기간 선택
  const [selectPeriod, setSelectPeriod] = useState("360일");
  // 스테이킹 기간 day1,2,3으로 변경
  const [periodDay, setPeriodDay] = useState("day2");
  // 계약 신청 체크 상태
  const [isCheck, setIsCheck] = useState(false);
  // 신청 수량 모달창 오픈
  const [isCntModal, setIsCntModal] = useState(false);
  // 거래 비밀번호 모달창 오픈
  const [isPwModal, setIsPwModal] = useState(false);
  // 계약 신청 모달창 오픈
  const [modalApply, setModalApply] = useState(false);
  // 모달창안에서 신청 수량
  const [modalCnt, setModalCnt] = useState('0');
  // input 신청 수량
  const [cnt, setCnt] = useState('0');
  // 모달창안에서 거래 비밀번호 
  const [modalPw, setModalPw] = useState("");
  // input 거래 비밀번호
  const [tradePw, setTradePw] = useState("");
  // 연 수익률 상태
  const [yearReturn, setYearReturn] = useState('0');
  // 신청 버튼 숨김 유무
  const [isHide, setIsHide] = useState(false);

  // 신청수량 모달 숫자클릭
  const handleNumberClick = (number) => {
    setModalCnt((prev) => {
      const newCnt = prev === '0' ? number : prev + number;
      const newCntValue = Number(newCnt);
  
      if (newCntValue >= 1000000000) { 
        alert("최대 수량을 초과할 수 없습니다.");
        return '0'; 
      }
  
      return newCnt; 
    });
  };

  // 거래 비밀번호 모달 오픈
  const openPwModal = () => {
    setIsPwModal(true);
    setModalPw("");
    setTradePw("");
  };

  // 신청수량 모달 뒤로가기
  const handleBackspace = () => {
    setModalCnt((prev) => prev.length > 1 ? prev.slice(0, -1) : '0');
  };

  // 거래 비밀번호 backspace
  const handlePwBackspace = () => {
    setModalPw(modalPw.slice(0, -1));
  };

  // 거래 비밀번호 reset
  const handleReset = () => {
    setModalPw('');
  };

  // 신청 수량 모달 설정 버튼
  const cntModalBtn = () => {
    if (modalCnt < 50000) {
      alert("최소 수량은 5만 개입니다.");
      setModalCnt('0');
      return;
    }

    if(dashboardData?.gmmtBalance < modalCnt){
      alert("보유수량을 초과할 수 없습니다.");
      setModalCnt('0');
      return;
    }
    setIsCntModal(false);
    setCnt(modalCnt);
  };

  // 거래 비밀번호 눈 아이콘 toggle
  const toggleEye = (index) => {
    const newEye = [...eye];
    newEye[index] = !newEye[index];
    setEye(newEye);
  };

  // 신청수량 모달창 오픈 (신청수량 input 클릭)
  const handleCntInput = () => {
    setIsCntModal(true);
    setModalCnt('0');
    setCnt('0');
    setYearReturn('0');
    setSelectPeriod('360일');
    setPeriodDay('day2');
    setTradePw("");
    setIsCheck(false);
  };

  // 거래 비밀번호 마지막 번호 클릭
  const pwLastClick = (newOtp) => {
    setIsPwModal(false);
    setTradePw(newOtp);
  };

  // 신청 최대수량 클릭시 소수점 버리고 정수
  const maxCnt = () => {
    
    const maxCount = Math.floor(dashboardData?.gmmtBalance);
    // 보유 gmmt가 10만개 미만일 경우
    if (maxCount < 50000) {
      alert("최소 수량은 5만 개입니다.");
      setCnt('0'); 
      return;
    }
    // 최대 수량을 초과하는 경우
    if (maxCount >= 100000000) {
      alert("최대 수량을 초과할 수 없습니다.");
      setCnt('0'); 
      return;
    }

    setCnt(maxCount.toString()); 
  };

  // 신청 수량, 기간 선택시 연 수익률 계산
  const yearCal = () => {
    const cntValue = Number(cnt); // cnt string -> Number로 타입 변경

    if (cntValue <= 0) {
      setYearReturn('0');
      return;
    }

    const foundRate = stakingPercentData.find((item) => cntValue >= item.startPoint && cntValue <= item.endPoint);

    if (foundRate) {
      const rate = foundRate.day2 || 0;
      setYearReturn(String(rate));
    } else {
      setYearReturn('0');
    }

  };

  // 현재 UTC 기준 날짜 시간 (신청일 구하기)
  const getTodayTime = () => {

    const now = new Date(); 
  
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0'); 
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  
  // 매주 화요일 00시 UTC (한국시간 오전 09시) (계약일 구하기)
  const getNextTuesday = () => {
    const now = new Date();
    const dayOfWeek = now.getUTCDay(); 
    
    const daysUntilTuesday =  (9 - dayOfWeek) % 7 || 7; ;
    
    const nextTuesdayUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + daysUntilTuesday, 0, 0, 0, 0));

    const year = nextTuesdayUTC.getUTCFullYear();
    const month = String(nextTuesdayUTC.getUTCMonth() + 1).padStart(2, '0');
    const day = String(nextTuesdayUTC.getUTCDate()).padStart(2, '0');
    const hours = String(nextTuesdayUTC.getUTCHours()).padStart(2, '0'); 
    const minutes = String(nextTuesdayUTC.getUTCMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`; 
  };

  // 스테이킹 계약 신청 체크
  const checkModal = () => {
    if (isCheck) {
      setIsCheck(false);
    } else {
      setModalApply(true); 
    }
  };

  // 스테이킹 신청하기 버튼
  const handleSubmit = async () => {

    if(cnt === '0'){
      alert('신청 수량을 입력해 주시길 바랍니다.');
      return;
    }

    if(tradePw === ""){
      alert("거래 비밀번호를 입력해 주세요.")
      return;
    }

    if(!isCheck){
      alert("스테이킹 유의사항에 동의해주세요.")
      return;
    }

    try {
      
      // const response1 = ({
      //   amount : parseInt(cnt), // number
      //   tradePw : tradePw, // string
      //   stakingDay : periodDay // string
      // });

      // console.table(response1);

      const response = await dispatch(stakingRequestApi({
        amount : parseInt(cnt), // number
        tradePw : tradePw, // string
        stakingDay : periodDay // string
      }));

      if(response.type === 'staking/request/fulfilled') {
        dispatch(dashBoardApi());
        alert("스테이킹 신청이 완료되었습니다.");
        navigate('/stakingList');
      }

    } catch(error) {
      console.error('스테이킹 신청하기 중 오류 발생', error)
    }
  };

  // gmmt 보유수량 조회
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(dashBoardApi());
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    dispatch(stakingPercentApi());
  },[dispatch]);

  // 연 수익률 변경
  useEffect(() => {
    yearCal();
  },[cnt,selectPeriod]);

  // 모달창 오픈시 스크롤 x
  useEffect(() => {
    document.body.style.overflow = isCntModal || isPwModal || modalApply ? 'hidden' : 'auto';
  }, [isCntModal, isPwModal, modalApply]);

  useEffect(() => {
    const now = new Date();
    const nowDate = getUtcTimeYmdHms(now);
  
    // UTC 당일 기준 00:00:00
    const startMidnight = new Date(now);
    startMidnight.setUTCHours(0, 0, 0, 0);
    // UTC 당일 기준 01:00:00
    const endOneAM = new Date(startMidnight);
    endOneAM.setUTCHours(1, 0, 0, 0);
  
    // UTC 당일 기준 23:55:00
    const startEvening = new Date(now);
    startEvening.setUTCHours(23, 55, 0, 0);
    // UTC 당일 기준 23:59:59:999
    const endMidnight = new Date(startEvening);
    endMidnight.setUTCHours(23, 59, 59, 999);
  
    const startMidnightStr = getUtcTimeYmdHms(startMidnight);
    const endOneAMStr = getUtcTimeYmdHms(endOneAM);
    const startEveningStr = getUtcTimeYmdHms(startEvening);
    const endMidnightStr = getUtcTimeYmdHms(endMidnight);
  
    // 만약 현재 UTC 시간이 00:00:00 과 같거나 크다면 && 현재 UTC 시간이 01:00:00 과 같거나 작다면
    // 또는 현재 UTC 시간이 23:55:00 과 같거나 크다면 && 현재 UTC 시간이 23:59:59 과 같거나 작다면 버튼 숨기기
    if ((nowDate >= startMidnightStr && nowDate <= endOneAMStr) || (nowDate >= startEveningStr && nowDate <= endMidnightStr)) {
      setIsHide(true);  
    } else { // 현재 UTC 시간이 00:00:00 ~ 01:00:00 이 아니고 23:55:00 ~ 23:59:59 가 아니라면 버튼 보이기 
      setIsHide(false);
    }

  }, []);

  return (
    <>
      {status === STATUSES.LOADING && <Loading />}
      <Header />
      <LayoutBg>
        <MainLayout>
          <Title>Staking 신청</Title>
          <ContentWrap>
            <ContentTitle>
              <p>Locked Staking 신청</p>
            </ContentTitle>
            <ContentRow>
              <ContentRowTitle>보유수량</ContentRowTitle>
              <ContentRowTxt>
                <GmmtCnt>
                  <GmmtIcon>
                    <img src='./../img/common_gmmt_logo.png' />
                  </GmmtIcon>
                  <p>{addCommasToNumber(formatBalance(dashboardData?.gmmtBalance))}<span> GMMT</span></p>
                </GmmtCnt>
              </ContentRowTxt>
            </ContentRow>
            <ContentRow className='application_cnt'>
              <ContentRowTitle className='application_cnt'>신청수량</ContentRowTitle>
              <ContentInputContainer>
                <ContentInputWrap onClick={handleCntInput}>
                  <ContentInput readOnly />
                  <ContentInputTxt>
                    <p>{cnt}</p>
                    <span>GMMT</span>
                  </ContentInputTxt>
                </ContentInputWrap>
                <ContentBtn onClick={maxCnt}>최대수량</ContentBtn>
              </ContentInputContainer>
            </ContentRow>
            {
              isCntModal &&
              <ModalCnt isOpen={isCntModal} closeModal={() => setIsCntModal(false)} title="수량 설정">
                <ModalWrap>
                  <ModalContentTitle>수량 입력</ModalContentTitle>
                  <ModalContentCnt>
                    <p>{addCommasToNumber(modalCnt)}</p>
                    <span>GMMT</span>
                  </ModalContentCnt>
                  <NumberPad
                    handleNumberClick={handleNumberClick}
                    handleBackspace={handleBackspace}
                    reset={() => setModalCnt('0')}
                  />
                  <ModalBtn onClick={cntModalBtn}>설정</ModalBtn>
                </ModalWrap>
              </ModalCnt>
            }
            <ContentRow>
              <ContentRowTitle>날짜</ContentRowTitle>
              <ContentRowTxt>{selectPeriod}</ContentRowTxt>
            </ContentRow>
            <ContentRow>
              <ContentRowTitle>연 수익률</ContentRowTitle>
              <ContentRowTxt>{yearReturn}%</ContentRowTxt>
            </ContentRow>
            <ContentRow>
              <ContentRowTitle>신청일</ContentRowTitle>
              <ContentRowTxt>{getTodayTime()}</ContentRowTxt>
            </ContentRow>
            <ContentRow>
              <ContentRowTitle>계약일</ContentRowTitle>
              <ContentRowTxt>{getNextTuesday()}</ContentRowTxt>
            </ContentRow>
            <ContentRow>
              <ContentRowTitle>Interest Period</ContentRowTitle>
              <ContentRowTxt>7 Days</ContentRowTxt>
            </ContentRow>
            <ContentTitle>
              <p>인증</p>
            </ContentTitle>
            <ContentRow>
              <ContentRowTitle>거래 비밀번호</ContentRowTitle>
              <ContentInputWrap>
                <ContentInput
                  readOnly
                  onClick={openPwModal}
                  value={tradePw}
                  placeholder='거래 비밀번호 입력' type={eye[0] ? 'text' : 'password'}
                />
                <img src={eye[0] ? './../img/common_view_ico@3x.png' : './../img/common_viewoff_ico@3x.png'} onClick={() => { toggleEye(0) }} />
              </ContentInputWrap>
            </ContentRow>
            {
             isPwModal &&
             <ModalPw
              isOpen={isPwModal}
              closeModal={() => setIsPwModal(false)}
              modalOtp={modalPw}
              setModalOtp={setModalPw}
              otpModalBtn={pwLastClick}
              handleReset={handleReset}
              handleBackspace={handlePwBackspace}
            />
            }
          </ContentWrap>
          <CheckWrap>
            <CheckIcon onClick={checkModal}>
              <img src={isCheck ? './../img/check_on.png' : './../img/check_off.png'} />
            </CheckIcon>
            <p onClick={checkModal}>스테이킹 계약을 신청합니다.</p>
          </CheckWrap>
          {
            !isHide &&
            <ModifyBtnWrap>
              <ModifyBtn onClick={handleSubmit}>신청하기</ModifyBtn>
            </ModifyBtnWrap>
          }
          {
            modalApply &&
            <ModalCnt isOpen={modalApply} closeModal={() => setModalApply(false)} title="스테이킹 유의사항">
                <ModalWrap className='apply'>
                  <p>1. rewards는 스테이킹 신청하신 종료일까지 연이자로 지급됩니다.</p><br />
                  <p>2. 스테이킹 신청후 14일동안만 대기중으로 해지가 가능합니다.</p><br />
                  <p>3. 이후 스테이킹 종료시까지 중도해지(lock)가 불가능하며, 스테이킹 미유지시 rewards를 지급받지 못합니다.</p><br />
                  <p>4. 매주 화요일 (한국시간 09시) 스테이킹 활성화를 통해 rewards와 출금이 하루동안 가능합니다.</p><br />
                  <p>5. rewards 출금시 수수료 2%와 가스비 0.0002가 차감하여 지급됩니다.</p><br />
                  <p>6. 스테이킹을 신청한 디지털자산은 스테이킹, 추가 수익 이외에 다른 용도로 사용되지 않습니다.</p><br />
                  <p>7. 해당 국가 상황에 따라 일부 서비스 이용에 제한이 있을 수 있습니다.</p><br />
                  <p>8. 운영 정책에 위반되는 행위를 할 경우 재단에서 신청된 스테이킹과 수익일체를 환수 할 수 있습니다.</p><br />
                  <p>9. 재단사정에 의해 rewards 지급율등 변동될수 있습니다.</p><br />
                  <p>10. 위 내용을 이해하시고 스테이킹 신청해 주시기 바랍니다.</p><br />
                  <p>* 동의 후 진행해 주세요. 감사합니다.</p>
                </ModalWrap>
                <ModalBtnWrap>
                  <ModalBtn className='delete' onClick={()=>{setModalApply(false)}}>닫기</ModalBtn>
                  <ModalBtn onClick={()=>{setIsCheck(true);setModalApply(false);}}>동의</ModalBtn>
                </ModalBtnWrap>
              </ModalCnt>
          }
        </MainLayout>
        <Footer />
      </LayoutBg>
    </>
  )
}

export default StakingRequest